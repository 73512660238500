<template>
  <div>
    <div class="card-toolbar mb-5">
        <router-link
            to="/customers/appointments/create"
            class="btn btn-primary font-weight-bolder"
            ><v-icon>mdi-plus</v-icon>{{ $t('appointments.add_appointment')}}</router-link
          >
        <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
            <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
        </button>
    </div>
    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
        <div class="card-body">
            <div class="m-form m-form--fit m--margin-bottom-20">
                  <div class="row">
                      <div class="form-group col-md-6">
                          <label>{{$t('appointments.customer')}}</label>
                          <!-- <select name="" id="customer" v-model="filters.customer_id" class="custom-select" >
                              <option v-for="row in customers" :value="row.id" :key="row.id">
                                  {{ row.fullname }}
                              </option>
                          </select> -->
                          <multiselect 
                                v-model="customer" 
                                :placeholder="$t('appointments.customer')" 
                                label="name" 
                                track-by="id" :options="customers" :multiple="false" :taggable="false"
                                :show-labels="false"
                                :show-no-options="false"
                                :show-no-results="false"
                                @search-change="getCustomers($event)">
                          </multiselect>
                      </div>
                      <div class="form-group col-md-6">
                          <label for="from_date">{{$t('from_date')}}</label>
                          <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
                      </div>

                      <div class="form-group col-md-6">
                          <label for="to_date">{{$t('to_date')}}</label>
                          <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
                      </div>

                      <div class="form-group col-md-6">
                          <label>{{$t('status')}}</label>
                          <select name="" id="status" v-model="filters.status" class="custom-select" >
                              <option v-for="row in status_list" :value="row.id" :key="row.id">
                                  {{ row.title }}
                              </option>
                          </select>
                      </div>
                      <div class="form-group col-md-6">
                          <label>{{$t('customer_appointment_report.procedure')}}</label>
                          <!-- <select name="" id="action" v-model="filters.action" class="custom-select" >
                              <option v-for="row in procedures" :value="row.id" :key="row.id">
                                  {{ row.name }}
                              </option>
                          </select> -->
                          <multiselect 
                                v-model="f_action" 
                                :placeholder="$t('customer_appointment_report.procedure')" 
                                label="name" 
                                track-by="id" :options="procedures" :multiple="false" :taggable="false"
                                :show-labels="false"
                                :show-no-options="false"
                                :show-no-results="false"
                                @search-change="getProcedures($event)">
                          </multiselect>
                      </div>

                      <div class="form-group d-inline-flex col-md-6">
                          <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                              <span><i class="fas fa-search"></i>
                                  <span>{{$t('search')}}</span>
                              </span>
                          </button>
                          <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                              <span><i class="fas fa-trash-restore"></i>
                                  <span>{{$t('reset_search')}}</span>
                              </span>
                          </button>
                      </div>
                  </div>
              </div>
        </div>
    </div>

    <!--begin::appointment-->
    <div class="card card-custom">
      
      <div class="card-body">
          <div>
              <div class="row justify-content-end p-4">
                  <custom-export-data v-if="$can('appointments.export_data')" :data-list="dataList" :fields="json_fields" :file-name="$t('appointments.appointments_management')"></custom-export-data>
              </div>
          </div>
          
        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

            <template slot="status" slot-scope="props">
                <b-form-checkbox  v-if="$can('appointments.change_status')"
                    size="lg" @change="changeStatus(props.row.id, props.row.is_active)" 
                    v-model="props.row.is_active" 
                    :name="'check-button'+props.row.id" 
                    switch :key="props.row.id">
                </b-form-checkbox>
              <b-form-checkbox v-else size="lg" :disabled="true" v-model="props.row.is_active" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>

            </template>

            <template slot="actions" slot-scope="props">
              <v-btn v-b-tooltip.hover :title="$t('edit')" v-if="$can('appointments.update')" icon color="pink" :to="`/customers/appointments/edit/${props.row.id}`">
                <v-icon small class="mr-2 text-info">mdi-pencil</v-icon>
                </v-btn>
                <v-icon small  class="text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('appointments.delete')" @click="deleteItem(props.row)">mdi-delete</v-icon>
            </template>
        </v-server-table>

      </div>
    </div>
    <!--end::appointment-->
  </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService  from "@/core/services/api.service";

    export default {
        name: "index-appointments",
        components: {},
        data() {
            return {
                showAdvancedSearch: false,
                dataList: [],

                filter: {
                    sortBy: 'id',
                },
                filters: {
                    customer_id: null,
                    from_date: null,
                    to_date: null,
                    action: null,
                    status: null,
                },
                columns: ['id','appintment_date', 'start_at', 'interval', 'customer_name','status', 'actions'],
                data: [],
                customers: [],
                customer : null,
                f_action : null,
                status_list: [],
                procedures: [],

                mainRoute:'customers/appointments',
                routeChangeStatus : 'customers/appointment/change-status',


            }
        },
        watch: {
            customer: function (val) {
                if (val) {
                    this.filters.customer_id = val.id;
                } else {
                    this.filters.customer_id = null;
                }
            },
            f_action: function (val) {
                if (val) {
                    this.filters.action = val.id;
                } else {
                    this.filters.action = null;
                }
            },
        },
        computed: {
            json_fields: function () {
                let fields = {};
                fields[this.$t('date')] = 'appintment_date';
                fields[this.$t('appointments.start_time')] = 'start_at';
                fields[this.$t('appointments.interval')] = 'interval';
                fields[this.$t('status')] = 'status';
                fields[this.$t('customer')] = 'customer_name';

                return fields;
            },
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        filterBy: that.$t('Filter')+' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading')+"...",
                    },
                    headings: {
                        id: that.$t('#'),
                        appintment_date: that.$t('date'),
                        start_at: that.$t('appointments.start_time'),
                        interval: that.$t('appointments.interval'),
                        customer_name: that.$t('customer'),
                        status: that.$t('status'),
                        actions: that.$t('global.actions'),
                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'created_at'},

                    alwaysShowPerPageSelect: false,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                            limit: data.perPage,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(`${that.mainRoute}`, {..._params})

                    },
                    responseAdapter(resp) {
                        that.dataList = resp.data.data.data;
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },
        },
        // created() {
        //     ApiService.get('appointments').then(response => {
        //         this.data = response.data.data.data;
        //     }).catch(e => {
        //         console.log(e);
        //     });
        // },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.appointments_management")}]);
            // this.getCustomers();
            this.getStatus();
            // this.getProcedures();
        },
        methods: {
            changeStatus(id, status) {
                ApiService.patch(this.routeChangeStatus + '/' + id, {
                    is_active: (status ? 1 : 0),
                }).then((response) => {
                    this.$refs.table.refresh();
                    this.$successAlert(response.data.message);
                }).catch((error) => {
                    this.$errorAlert(error)
                });
            },
          openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.customer_id = null;
                this.filters.from_date = null;
                this.filters.to_date = null;
                this.filters.action = null;
                this.filters.status = null;
                this.customer = null;

                this.$refs.table.refresh();
            },
            getFetch() {
                this.$refs.table.refresh();
            },

            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },
            
            getCustomers(filter) {
                if(filter && filter.length >= 3){
                    ApiService.get(`base/dependency/customers`,{params:{filter: filter}}).then((response) => {
                        this.customers = response.data.data;
                    });
                }else{
                    this.customers = [];
                }
            },
            getStatus() {
                ApiService.get("base/dependency/status_list").then((response) => {
                    this.status_list = response.data.data;
                });
            },
            getProcedures(filter) {
                if(filter && filter.length >= 3){
                    ApiService.get(`base/dependency/procedures`,{params:{filter: filter}}).then((response) => {
                        this.procedures = response.data.data;
                    });
                }else{
                    this.procedures = [];
                }
            },
            actionDelete(item){
                ApiService.delete(`${this.mainRoute}/${item.id}`).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error)
                })
            },
        },
    };
</script>
